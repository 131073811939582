<!-- 详情与编辑、添加都放同一页 -->
<template>
  <div>
    <!-- 基本信息 -->
    <div class="pad-container">
      <el-form
        class="mt-24"
        label-width="120px"
        :model="act_form"
        :rules="rules"
        ref="act_form"
      >
        <p class="fs-18 c-9 fw-7">基础信息</p>
        <el-form-item label="商品分类" prop="goodsClassifyId">
          <el-cascader
            v-model="act_form.goodsClassifyId"
            :show-all-levels="false"
            :options="mainSpecialOptions"
            class="form-input"
            :props="props"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="商品名称" prop="goodsName">
          <el-input
            class="form-input"
            placeholder="请输入商品标题"
            v-model="act_form.goodsName"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-divider></el-divider>
        <p class="fs-18 c-9 fw-7">详情信息</p>
        <el-form-item label="商品条码(UPC)" prop="goodsBarcode">
          <el-input
            class="form-input"
            placeholder="请输入商品条码(UPC)"
            v-model="act_form.goodsBarcode"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品编码" prop="goodsCode">
          <el-input
            class="form-input"
            placeholder="请输入商品编码"
            v-model="act_form.goodsCode"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属门店" prop="storeId">
          <el-select
            v-model="act_form.storeId"
            placeholder="请选择"
            class="form-input"
          >
            <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="item.storeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="实价" prop="actualPrice">
          <el-input
            placeholder="请输入实价"
            v-model="act_form.actualPrice"
            class="form-input"
            @keyup.native="act_form.actualPrice = onInput(act_form.actualPrice)"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="原价" prop="originalPrice">
          <el-input
            placeholder="请输入原价"
            v-model="act_form.originalPrice"
            class="form-input"
            @keyup.native="
              act_form.originalPrice = onInput(act_form.originalPrice)
            "
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="库存" prop="stock">
          <el-input
            class="form-input"
            placeholder="请输入库存"
            v-model="act_form.stock"
            @keyup.native="act_form.stock = onInput(act_form.stock, 0)"
          ></el-input>
        </el-form-item>
        <el-form-item label="重量" prop="goodsWeight">
          <el-input
            v-model="act_form.goodsWeight"
            class="form-input"
            @keyup.native="
              act_form.goodsWeight = onInput(act_form.goodsWeight, 1, 3)
            "
          >
            <template slot="append">g</template>
          </el-input>
        </el-form-item>
        <el-form-item label="商品角标">
          <el-select
            v-model="act_form.cornerMarkId"
            placeholder="请选择"
            class="form-input"
            @change="cornerChange"
            clearable
          >
            <el-option
              v-for="(item, index) in cornerList"
              :key="index"
              :label="item.cornerMarkName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品副标题" prop="goodsSubhead">
          <el-input
            v-model="act_form.goodsSubhead"
            class="form-input"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="商品主图" prop="goodsImgs">
          <UploadPics
            :uploadType="'main'"
            :imgLimit="1"
            :imgSize="'270*270'"
            :imgsPreview="act_form.goodsImgs"
            @getImgData="getImgData"
            :toEnable="!showDetails"
          />
        </el-form-item>
        <el-form-item label="商品辅图" prop="subImgs">
          <DragImgs
            @getPictures="getPictures"
            :imgSize="'750*750'"
            :pictures="pictures"
          />
        </el-form-item>
        <el-form-item label="商品详情">
          <tinymce v-model="act_form.goodsDescribe" :height="300" />
        </el-form-item>
      </el-form>
      <div class="flx-row ali-c js-fe mt-50">
        <el-button plain class="form-button" @click="cancel">取消</el-button>
        <el-button class="form-button" type="primary" @click="submit"
          >确定</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import DragImgs from "@/components/DragImgs";
import UploadPics from "@/components/UploadPics";
import * as Interface from "@/api/common";
import {
  queryGoodsInfoDetail,
  updateGoodsFreshInfo,
  addLrGoodsFreshInfo,
} from "@/api/market/flesh_new";
export default {
  components: {
    DragImgs,
    UploadPics,
    Tinymce,
  },
  data() {
    return {
      shopOptions: [
        {
          label: "江北店",
          value: 1,
        },
        {
          label: "江源店",
          value: 2,
        },
      ],
      pictures: [],
      act_form: {
        goodsType: 2,
        subImgs: "",
        goodsImgs: "",
        goodsDescribe: "",
        goodsWeight: "",
        stock: "",
        actualPrice: "",
        goodsClassifyId: "",
        originalPrice: "",
        goodsBarcode: "",
        goodsCode: "",
        goodsSubhead: "",
        goodsName: "",
        storeId: "",
        cornerMarkId: "",
      },
      rules: {
        goodsImgs: [
          { required: true, message: "主图不能为空", trigger: "blur" },
        ],
        goodsSubhead: [
          { required: true, message: "商品副标题不能为空", trigger: "blur" },
        ],
        goodsName: [
          { required: true, message: "商品名称不能为空", trigger: "blur" },
        ],
        mainSpecial: [
          { required: true, message: "商品类目不能为空", trigger: "blur" },
        ],
        subImgs: [{ required: true, message: "辅图不能为空", trigger: "blur" }],
        goodsWeight: [
          { required: true, message: "重量不能为空", trigger: "blur" },
        ],
        stock: [{ required: true, message: "库存不能为空", trigger: "blur" }],
        actualPrice: [
          { required: true, message: "实价不能为空", trigger: "blur" },
        ],
        originalPrice: [
          { required: true, message: "原价不能为空", trigger: "blur" },
        ],
        storeId: [
          { required: true, message: "所属门店不能为空", trigger: "blur" },
        ],
        goodsCode: [
          { required: true, message: "商品编码不能为空", trigger: "blur" },
        ],
        goodsBarcode: [
          { required: true, message: "商品条码不能为空", trigger: "blur" },
        ],
        goodsClassifyId: [
          { required: true, message: "商品分类不能为空", trigger: "blur" },
        ],
      },
      showGoods: false,
      goodsTable: [],
      search_goods: "",
      search_barCode: "",
      hasSelected: false,
      addFlag: true,
      actId: "",
      total: 0,
      pageNum: 1,
      pageSize: 5,
      pageSizes: [5, 8, 10, 15],
      mainImgStr: "",
      showDetails: false,
      firstFlag: false,
      mainSpecialOptions: [],
      storeList: [],
      cornerList: [],
      props: {
        label: "classifyName",
        value: "id",
        children: "childList",
      },
      selected_temp: [], // 全局勾选数组
    };
  },
  mounted() {
    this.getStoreList();
    this.queryAllCornerMark();
    let id = this.$route.query.id;
    id ? this.init(id) : this.queryGoodsType();
  },
  filters: {
    goodsTypeFilter(val) {
      switch (val) {
        case 1:
          return "商品";
        case 2:
          return "优惠券";
        case 3:
          return "商兑换券";
        default:
          return "--";
      }
    },
  },
  methods: {
    cornerChange(val) {
      this.act_form.cornerMarkId = val;
      this.$forceUpdate();
    },
    // 递归拿到父id
    getParentId(data) {
      data.map((item) => {
        if (item.childList) {
          this.getParentId(item.childList);
        }
        if (item.id == this.act_form.classifyId) {
          this.act_form.goodsClassifyId = [item.parentId, item.id];
          delete this.act_form.classifyId;
        }
      });
    },
    // 数据初始化
    init(id) {
      Promise.all([this.getDetails(id), this.queryGoodsType()]).then(() => {
        this.getParentId(this.mainSpecialOptions);
      });
    },
    // 获取详情
    getDetails(id) {
      queryGoodsInfoDetail(id).then((res) => {
        this.act_form = res.data.body;
        this.act_form.cornerMarkId = res.data.body.cornerId;
        delete this.act_form.cornerId;
        this.pictures = this.act_form.subImgs.split(";");
        resolve();
      });
    },
    // 查询所有角标
    queryAllCornerMark() {
      Interface.queryAllCornerMark({}).then((res) => {
        this.cornerList = res.data.body.list;
      });
    },
    // 获取店铺列表
    getStoreList() {
      Interface.getStoreList().then((res) => {
        this.storeList = res.data.body.list;
      });
    },
    // 获取商品分类
    queryGoodsType() {
      return new Promise((resolve, reject) => {
        Interface.queryGoodsType({ goodsType: 1 })
          .then((res) => {
            this.mainSpecialOptions = res.data.body;
            resolve();
          })
          .catch((err) => {
            reject();
          });
      });
    },
    // 获取图片
    getPictures(subImgs) {
      // console.log(subImgs)
      this.act_form.subImgs = subImgs.join(";");
    },
    // 点击其他页
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    // 获取图片数据
    getImgData(imgData, type) {
      type == "main" ? (this.act_form.goodsImgs = imgData.mainImg) : "";
    },
    // 返回
    cancel() {
      this.$router.go(-1);
    },
    // 提交表单-验证
    submit() {
      this.$refs["act_form"].validate((valid) => {
        if (!valid) {
          this.$message.warning("表单信息不完整（有误）");
          return;
        }
        this.submitForm();
      });
    },
    // 提交表单-提交
    submitForm() {
      let data = JSON.parse(JSON.stringify(this.act_form));
      if (Array.isArray(data.goodsClassifyId)) {
        data.goodsClassifyId =
          data.goodsClassifyId[data.goodsClassifyId.length - 1];
      }
      data.goodsWeight = this.removeDot(data.goodsWeight);
      data.actualPrice = this.removeDot(data.actualPrice);
      data.originalPrice = this.removeDot(data.originalPrice);
      delete data.cornerMarkName;
      let id = this.$route.query.id;
      let url = "";
      // console.log(data)
      if (id) {
        updateGoodsFreshInfo(data).then((res) => {
          this.$message.success(res.data.body);
          this.$router.go(-1);
        });
      } else {
        addLrGoodsFreshInfo(data).then((res) => {
          this.$message.success(res.data.body);
          this.$router.go(-1);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tip-text-info {
  color: #409eff;
  cursor: pointer;
}
.el-avatar /deep/ img {
  width: 100%;
}
</style>