import request from '@/utils/request'

// 获取详情 "/shd-operation/lrGoodsInfo/queryGoodsInfoDetail?id=" + id

export function queryGoodsInfoDetail (id) {
    return request({
        url: `/shd-operation/lrGoodsInfo/queryGoodsInfoDetail?id=${id}`,
        method: 'get'
    })
}
//编辑 /shd-operation/lrGoodsInfo/updateGoodsFreshInfo
export function updateGoodsFreshInfo (data) {
    return request({
        url: '/shd-operation/lrGoodsInfo/updateGoodsFreshInfo',
        method: 'post',
        data
    })
}


//新增 /shd-operation/lrGoodsInfo/addLrGoodsFreshInfo
export function addLrGoodsFreshInfo (data) {
    return request({
        url: '/shd-operation/lrGoodsInfo/addLrGoodsFreshInfo',
        method: 'post',
        data
    })
}